import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _19ace550 = () => interopDefault(import('../pages/certificates/index.vue' /* webpackChunkName: "pages/certificates/index" */))
const _453525b4 = () => interopDefault(import('../pages/drivers-and-manuals.vue' /* webpackChunkName: "pages/drivers-and-manuals" */))
const _35066b04 = () => interopDefault(import('../pages/service-center.vue' /* webpackChunkName: "pages/service-center" */))
const _3fc6f8aa = () => interopDefault(import('../pages/where-to-buy.vue' /* webpackChunkName: "pages/where-to-buy" */))
const _55cf2b5e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ec05ed6c = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _17f64408 = () => interopDefault(import('../pages/certificates/_slug.vue' /* webpackChunkName: "pages/certificates/_slug" */))
const _57a864e9 = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _593c6dee = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/certificates",
    component: _19ace550,
    pathToRegexpOptions: {"strict":true},
    name: "certificates___en"
  }, {
    path: "/certificates",
    component: _19ace550,
    pathToRegexpOptions: {"strict":true},
    name: "certificates___bg"
  }, {
    path: "/certificates",
    component: _19ace550,
    pathToRegexpOptions: {"strict":true},
    name: "certificates___cs"
  }, {
    path: "/certificates",
    component: _19ace550,
    pathToRegexpOptions: {"strict":true},
    name: "certificates___hu"
  }, {
    path: "/certificates",
    component: _19ace550,
    pathToRegexpOptions: {"strict":true},
    name: "certificates___pl"
  }, {
    path: "/certificates",
    component: _19ace550,
    pathToRegexpOptions: {"strict":true},
    name: "certificates___sk"
  }, {
    path: "/certificates",
    component: _19ace550,
    pathToRegexpOptions: {"strict":true},
    name: "certificates___uk"
  }, {
    path: "/certificates",
    component: _19ace550,
    pathToRegexpOptions: {"strict":true},
    name: "certificates___ro"
  }, {
    path: "/certificates",
    component: _19ace550,
    pathToRegexpOptions: {"strict":true},
    name: "certificates___ru"
  }, {
    path: "/certificates",
    component: _19ace550,
    pathToRegexpOptions: {"strict":true},
    name: "certificates___es"
  }, {
    path: "/certificates",
    component: _19ace550,
    pathToRegexpOptions: {"strict":true},
    name: "certificates___de"
  }, {
    path: "/certificates",
    component: _19ace550,
    pathToRegexpOptions: {"strict":true},
    name: "certificates___el"
  }, {
    path: "/certificates",
    component: _19ace550,
    pathToRegexpOptions: {"strict":true},
    name: "certificates___kz"
  }, {
    path: "/drivers-and-manuals",
    component: _453525b4,
    pathToRegexpOptions: {"strict":true},
    name: "drivers-and-manuals___en"
  }, {
    path: "/drivers-and-manuals",
    component: _453525b4,
    pathToRegexpOptions: {"strict":true},
    name: "drivers-and-manuals___bg"
  }, {
    path: "/drivers-and-manuals",
    component: _453525b4,
    pathToRegexpOptions: {"strict":true},
    name: "drivers-and-manuals___cs"
  }, {
    path: "/drivers-and-manuals",
    component: _453525b4,
    pathToRegexpOptions: {"strict":true},
    name: "drivers-and-manuals___hu"
  }, {
    path: "/drivers-and-manuals",
    component: _453525b4,
    pathToRegexpOptions: {"strict":true},
    name: "drivers-and-manuals___pl"
  }, {
    path: "/drivers-and-manuals",
    component: _453525b4,
    pathToRegexpOptions: {"strict":true},
    name: "drivers-and-manuals___sk"
  }, {
    path: "/drivers-and-manuals",
    component: _453525b4,
    pathToRegexpOptions: {"strict":true},
    name: "drivers-and-manuals___uk"
  }, {
    path: "/drivers-and-manuals",
    component: _453525b4,
    pathToRegexpOptions: {"strict":true},
    name: "drivers-and-manuals___ro"
  }, {
    path: "/drivers-and-manuals",
    component: _453525b4,
    pathToRegexpOptions: {"strict":true},
    name: "drivers-and-manuals___ru"
  }, {
    path: "/drivers-and-manuals",
    component: _453525b4,
    pathToRegexpOptions: {"strict":true},
    name: "drivers-and-manuals___es"
  }, {
    path: "/drivers-and-manuals",
    component: _453525b4,
    pathToRegexpOptions: {"strict":true},
    name: "drivers-and-manuals___de"
  }, {
    path: "/drivers-and-manuals",
    component: _453525b4,
    pathToRegexpOptions: {"strict":true},
    name: "drivers-and-manuals___el"
  }, {
    path: "/drivers-and-manuals",
    component: _453525b4,
    pathToRegexpOptions: {"strict":true},
    name: "drivers-and-manuals___kz"
  }, {
    path: "/service-center",
    component: _35066b04,
    pathToRegexpOptions: {"strict":true},
    name: "service-center___en"
  }, {
    path: "/service-center",
    component: _35066b04,
    pathToRegexpOptions: {"strict":true},
    name: "service-center___bg"
  }, {
    path: "/service-center",
    component: _35066b04,
    pathToRegexpOptions: {"strict":true},
    name: "service-center___cs"
  }, {
    path: "/service-center",
    component: _35066b04,
    pathToRegexpOptions: {"strict":true},
    name: "service-center___hu"
  }, {
    path: "/service-center",
    component: _35066b04,
    pathToRegexpOptions: {"strict":true},
    name: "service-center___pl"
  }, {
    path: "/service-center",
    component: _35066b04,
    pathToRegexpOptions: {"strict":true},
    name: "service-center___sk"
  }, {
    path: "/service-center",
    component: _35066b04,
    pathToRegexpOptions: {"strict":true},
    name: "service-center___uk"
  }, {
    path: "/service-center",
    component: _35066b04,
    pathToRegexpOptions: {"strict":true},
    name: "service-center___ro"
  }, {
    path: "/service-center",
    component: _35066b04,
    pathToRegexpOptions: {"strict":true},
    name: "service-center___ru"
  }, {
    path: "/service-center",
    component: _35066b04,
    pathToRegexpOptions: {"strict":true},
    name: "service-center___es"
  }, {
    path: "/service-center",
    component: _35066b04,
    pathToRegexpOptions: {"strict":true},
    name: "service-center___de"
  }, {
    path: "/service-center",
    component: _35066b04,
    pathToRegexpOptions: {"strict":true},
    name: "service-center___el"
  }, {
    path: "/service-center",
    component: _35066b04,
    pathToRegexpOptions: {"strict":true},
    name: "service-center___kz"
  }, {
    path: "/where-to-buy",
    component: _3fc6f8aa,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-buy___en"
  }, {
    path: "/where-to-buy",
    component: _3fc6f8aa,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-buy___bg"
  }, {
    path: "/where-to-buy",
    component: _3fc6f8aa,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-buy___cs"
  }, {
    path: "/where-to-buy",
    component: _3fc6f8aa,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-buy___hu"
  }, {
    path: "/where-to-buy",
    component: _3fc6f8aa,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-buy___pl"
  }, {
    path: "/where-to-buy",
    component: _3fc6f8aa,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-buy___sk"
  }, {
    path: "/where-to-buy",
    component: _3fc6f8aa,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-buy___uk"
  }, {
    path: "/where-to-buy",
    component: _3fc6f8aa,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-buy___ro"
  }, {
    path: "/where-to-buy",
    component: _3fc6f8aa,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-buy___ru"
  }, {
    path: "/where-to-buy",
    component: _3fc6f8aa,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-buy___es"
  }, {
    path: "/where-to-buy",
    component: _3fc6f8aa,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-buy___de"
  }, {
    path: "/where-to-buy",
    component: _3fc6f8aa,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-buy___el"
  }, {
    path: "/where-to-buy",
    component: _3fc6f8aa,
    pathToRegexpOptions: {"strict":true},
    name: "where-to-buy___kz"
  }, {
    path: "/",
    component: _55cf2b5e,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/",
    component: _55cf2b5e,
    pathToRegexpOptions: {"strict":true},
    name: "index___bg"
  }, {
    path: "/",
    component: _55cf2b5e,
    pathToRegexpOptions: {"strict":true},
    name: "index___cs"
  }, {
    path: "/",
    component: _55cf2b5e,
    pathToRegexpOptions: {"strict":true},
    name: "index___hu"
  }, {
    path: "/",
    component: _55cf2b5e,
    pathToRegexpOptions: {"strict":true},
    name: "index___pl"
  }, {
    path: "/",
    component: _55cf2b5e,
    pathToRegexpOptions: {"strict":true},
    name: "index___sk"
  }, {
    path: "/",
    component: _55cf2b5e,
    pathToRegexpOptions: {"strict":true},
    name: "index___uk"
  }, {
    path: "/",
    component: _55cf2b5e,
    pathToRegexpOptions: {"strict":true},
    name: "index___ro"
  }, {
    path: "/",
    component: _55cf2b5e,
    pathToRegexpOptions: {"strict":true},
    name: "index___ru"
  }, {
    path: "/",
    component: _55cf2b5e,
    pathToRegexpOptions: {"strict":true},
    name: "index___es"
  }, {
    path: "/",
    component: _55cf2b5e,
    pathToRegexpOptions: {"strict":true},
    name: "index___de"
  }, {
    path: "/",
    component: _55cf2b5e,
    pathToRegexpOptions: {"strict":true},
    name: "index___el"
  }, {
    path: "/",
    component: _55cf2b5e,
    pathToRegexpOptions: {"strict":true},
    name: "index___kz"
  }, {
    path: "/category/:slug?",
    component: _ec05ed6c,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___en"
  }, {
    path: "/category/:slug?",
    component: _ec05ed6c,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___bg"
  }, {
    path: "/category/:slug?",
    component: _ec05ed6c,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___cs"
  }, {
    path: "/category/:slug?",
    component: _ec05ed6c,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___hu"
  }, {
    path: "/category/:slug?",
    component: _ec05ed6c,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___pl"
  }, {
    path: "/category/:slug?",
    component: _ec05ed6c,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___sk"
  }, {
    path: "/category/:slug?",
    component: _ec05ed6c,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___uk"
  }, {
    path: "/category/:slug?",
    component: _ec05ed6c,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___ro"
  }, {
    path: "/category/:slug?",
    component: _ec05ed6c,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___ru"
  }, {
    path: "/category/:slug?",
    component: _ec05ed6c,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___es"
  }, {
    path: "/category/:slug?",
    component: _ec05ed6c,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___de"
  }, {
    path: "/category/:slug?",
    component: _ec05ed6c,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___el"
  }, {
    path: "/category/:slug?",
    component: _ec05ed6c,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___kz"
  }, {
    path: "/certificates/:slug",
    component: _17f64408,
    pathToRegexpOptions: {"strict":true},
    name: "certificates-slug___en"
  }, {
    path: "/certificates/:slug",
    component: _17f64408,
    pathToRegexpOptions: {"strict":true},
    name: "certificates-slug___bg"
  }, {
    path: "/certificates/:slug",
    component: _17f64408,
    pathToRegexpOptions: {"strict":true},
    name: "certificates-slug___cs"
  }, {
    path: "/certificates/:slug",
    component: _17f64408,
    pathToRegexpOptions: {"strict":true},
    name: "certificates-slug___hu"
  }, {
    path: "/certificates/:slug",
    component: _17f64408,
    pathToRegexpOptions: {"strict":true},
    name: "certificates-slug___pl"
  }, {
    path: "/certificates/:slug",
    component: _17f64408,
    pathToRegexpOptions: {"strict":true},
    name: "certificates-slug___sk"
  }, {
    path: "/certificates/:slug",
    component: _17f64408,
    pathToRegexpOptions: {"strict":true},
    name: "certificates-slug___uk"
  }, {
    path: "/certificates/:slug",
    component: _17f64408,
    pathToRegexpOptions: {"strict":true},
    name: "certificates-slug___ro"
  }, {
    path: "/certificates/:slug",
    component: _17f64408,
    pathToRegexpOptions: {"strict":true},
    name: "certificates-slug___ru"
  }, {
    path: "/certificates/:slug",
    component: _17f64408,
    pathToRegexpOptions: {"strict":true},
    name: "certificates-slug___es"
  }, {
    path: "/certificates/:slug",
    component: _17f64408,
    pathToRegexpOptions: {"strict":true},
    name: "certificates-slug___de"
  }, {
    path: "/certificates/:slug",
    component: _17f64408,
    pathToRegexpOptions: {"strict":true},
    name: "certificates-slug___el"
  }, {
    path: "/certificates/:slug",
    component: _17f64408,
    pathToRegexpOptions: {"strict":true},
    name: "certificates-slug___kz"
  }, {
    path: "/product/:slug?",
    component: _57a864e9,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___en"
  }, {
    path: "/product/:slug?",
    component: _57a864e9,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___bg"
  }, {
    path: "/product/:slug?",
    component: _57a864e9,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___cs"
  }, {
    path: "/product/:slug?",
    component: _57a864e9,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___hu"
  }, {
    path: "/product/:slug?",
    component: _57a864e9,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___pl"
  }, {
    path: "/product/:slug?",
    component: _57a864e9,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___sk"
  }, {
    path: "/product/:slug?",
    component: _57a864e9,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___uk"
  }, {
    path: "/product/:slug?",
    component: _57a864e9,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___ro"
  }, {
    path: "/product/:slug?",
    component: _57a864e9,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___ru"
  }, {
    path: "/product/:slug?",
    component: _57a864e9,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___es"
  }, {
    path: "/product/:slug?",
    component: _57a864e9,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___de"
  }, {
    path: "/product/:slug?",
    component: _57a864e9,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___el"
  }, {
    path: "/product/:slug?",
    component: _57a864e9,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___kz"
  }, {
    path: "/:slug",
    component: _593c6dee,
    pathToRegexpOptions: {"strict":true},
    name: "slug___en"
  }, {
    path: "/:slug",
    component: _593c6dee,
    pathToRegexpOptions: {"strict":true},
    name: "slug___bg"
  }, {
    path: "/:slug",
    component: _593c6dee,
    pathToRegexpOptions: {"strict":true},
    name: "slug___cs"
  }, {
    path: "/:slug",
    component: _593c6dee,
    pathToRegexpOptions: {"strict":true},
    name: "slug___hu"
  }, {
    path: "/:slug",
    component: _593c6dee,
    pathToRegexpOptions: {"strict":true},
    name: "slug___pl"
  }, {
    path: "/:slug",
    component: _593c6dee,
    pathToRegexpOptions: {"strict":true},
    name: "slug___sk"
  }, {
    path: "/:slug",
    component: _593c6dee,
    pathToRegexpOptions: {"strict":true},
    name: "slug___uk"
  }, {
    path: "/:slug",
    component: _593c6dee,
    pathToRegexpOptions: {"strict":true},
    name: "slug___ro"
  }, {
    path: "/:slug",
    component: _593c6dee,
    pathToRegexpOptions: {"strict":true},
    name: "slug___ru"
  }, {
    path: "/:slug",
    component: _593c6dee,
    pathToRegexpOptions: {"strict":true},
    name: "slug___es"
  }, {
    path: "/:slug",
    component: _593c6dee,
    pathToRegexpOptions: {"strict":true},
    name: "slug___de"
  }, {
    path: "/:slug",
    component: _593c6dee,
    pathToRegexpOptions: {"strict":true},
    name: "slug___el"
  }, {
    path: "/:slug",
    component: _593c6dee,
    pathToRegexpOptions: {"strict":true},
    name: "slug___kz"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
